<template>
  <div>
    <a-table
      :dataSource="list"
      :loading="loading"
      :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
      rowKey="id"
      :row-selection="{
        selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <a-table-column key="name" title="名称" data-index="name" />
      <a-table-column key="remarks" title="规则" data-index="remark" />
    </a-table>

    <div class="center">
      <a-space>
        <a-button type="primary" @click="save">保存</a-button>
        <a-button @click="close">关闭</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/facility";
import { fetchDetail, bindDevice } from "@/api/warning";
export default {
  data() {
    return {
      list: [],
      loading: false,
      current: 1,
      pageSize: 10,
      total: 0,

      selectedRowKeys: [],
    };
  },
  mounted() {
    this.getList();

    fetchDetail({
      id: this.$route.query.id,
    }).then((res) => {
      console.log("res", res);
      if (res && Array.isArray(res.deviceList)) {
        this.selectedRowKeys = res.deviceList.map((item) => item.deviceId);
      }
    });
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },
    save() {
      if (this.selectedRowKeys.length > 0) {
        const { id } = this.$route.query;

        const deviceList = this.selectedRowKeys.map((key) => {
          const obj = this.list.find((element) => element.id === key);
          return {
            deviceCode: obj.code,
            deviceId: key,
            deviceName: obj.name,
            pid: id,
          };
        });
        bindDevice({
          id,
          deviceList,
        }).then(() => {
          this.close();
        });
      } else {
        this.$message.error("请选择设备");
      }
    },
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
