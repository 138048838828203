var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-table',{attrs:{"dataSource":_vm.list,"loading":_vm.loading,"pagination":{
      total: _vm.total,
      current: _vm.current,
      pageSize: _vm.pageSize,
      showTotal: (total) => `共 ${total} 条记录`,
    },"rowKey":"id","row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    }}},[_c('a-table-column',{key:"name",attrs:{"title":"名称","data-index":"name"}}),_c('a-table-column',{key:"remarks",attrs:{"title":"规则","data-index":"remark"}})],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]),_c('a-button',{on:{"click":_vm.close}},[_vm._v("关闭")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }