import request from './request'

export function fetchList(params) {
    return request({
        url: '/model-analysis/pipe/device/list',
        params,
    })
}

export function add(data) {
    return request({
        url: '/model-analysis/pipe/device/add',
        method: 'post',
        data,
    })
}
export function fetchDetail({ id }) {
    return request({
        url: '/model-analysis/pipe/device/query/' + id,
        method: 'post',
    })
}
export function edit(data) {
    return request({
        url: '/model-analysis/pipe/device/update',
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/model-analysis/pipe/device/delete',
        method: 'post',
        data,
    })
}